export function checkIfEmpty(inSel, inName) {
    if ($(inSel).val() === "") {
        $(inSel).addClass("is-invalid");
        toastr.info(inName + " muß angegeben werden", "Pflichtfelder");
        return false;
    } else {
        $(inSel).removeClass("is-invalid");
        return true;
    }
}

/*
   Reads all Configuration Parameters from PEA
   data > array of Parameters

 */
export function getAllConfig() {

    $.ajax({
        url: "/api/pea/getallconfig",
        type: "GET",
        headers: {
            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
        },
        success: function (data) {
           if (data.success == true)
           {
              // callbackfunktion aufrufen ... TODO
           }else
           {
               toastr.info("Problems reading configs","Config Read failt");
           }

        },
    });
}

export function initTinyMce( selector, uploadUrl ){
    tinymce.init({
        selector: '#'+selector,
        plugins: 'image code',
        toolbar: 'undo redo | link image | code | bold italic | alignleft aligncenter alignright alignjustify',
        /* enable title field in the Image dialog*/
        image_title: true,
        /* enable automatic uploads of images represented by blob or data URIs*/
        automatic_uploads: true,
        /*
          URL of our upload handler (for more details check: https://www.tiny.cloud/docs/configure/file-image-upload/#images_upload_url)
          images_upload_url: 'postAcceptor.php',
          here we add custom filepicker only to Image dialog
        */
        images_upload_url: uploadUrl,
        file_picker_types: 'image',
        /* and here's our custom image picker*/
        file_picker_callback: function(cb, value, meta) {
            var input = document.createElement('input');
            input.setAttribute('type', 'file');
            input.setAttribute('accept', 'image/*');
            input.onchange = function() {
                var file = this.files[0];

                var reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = function () {
                    var id = 'blobid' + (new Date()).getTime();
                    var blobCache =  tinymce.activeEditor.editorUpload.blobCache;
                    var base64 = reader.result.split(',')[1];
                    var blobInfo = blobCache.create(id, file, base64);
                    blobCache.add(blobInfo);
                    cb(blobInfo.blobUri(), { title: file.name });
                };
            };
            input.click();
        },
        content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:16px }'
    });
}
